<template>
  <div
    class="modal inmodal"
    id="viewModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated fadeIn">
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(key, value, index) in data" v-bind:key="index" style="white-space: break-spaces;">
                <td>
                  <span class="label label-default"> {{ index + 1 }} </span>
                </td>
                <td>{{ value }}</td>
                <td>{{ key }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal" id="closeBtn">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../../plugin/AxiosService.js";
import Constant from "../../plugin/Constant.js";
import EventBus from "../../plugin/event-bus";

export default {
  props: {
    data: Object,
  },
  mounted: function () {},
  methods: {},
};
</script>

<style scoped></style>
