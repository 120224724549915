<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>User Audit</h5>
          </div>

          <div class="ibox-content">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="onSubmit" autocomplete="off">
                <div class="row" style="margin-left: 0.2%">
                  <div class="form-group col-md-2">
                    <label>UI Name</label>
                    <select class="form-control" v-model="userAuditModel.name">
                      <option value>---- Select ----</option>
                      <option
                        v-for="(value, index) in uiNames"
                        v-bind:key="index"
                        :value="value"
                      >{{ value }}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Action</label>
                    <select class="form-control" v-model="userAuditModel.action">
                      <option value>---- Select ----</option>
                      <option
                        v-for="(value, index) in actions"
                        v-bind:key="index"
                        :value="value"
                      >{{ value }}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>UUID</label>
                    <input
                      type="text"
                      placeholder="uuid"
                      class="form-control"
                      v-model="userAuditModel.uuid"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>
                      Date *
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Only 7 days date selection allowed"
                      ></i>
                    </label>
                    <div class="input-group date">
                      <ValidationProvider
                        name="Date"
                        :rules="{ required: true }"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          class="form-control"
                          type="text"
                          :name="datePicker"
                          :id="datePicker"
                          autocomplete="off"
                          :class="classes"
                          v-model="userAuditModel.requestDate"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group col-md-2">
                    <label>User Id</label>
                    <input
                      type="text"
                      placeholder="User Id"
                      class="form-control"
                      v-model="userAuditModel.userId"
                    />
                  </div>

                  <div class="form-group" style="margin-top: 1.6%">
                    <button
                      type="button"
                      class="btn btn-primary btn-lg ladda-button ladda-button-demo"
                      data-style="zoom-in"
                      :disabled="invalid"
                      @click="submitData"
                    >
                      <i class="fa fa-search"></i>&nbsp;&nbsp; Search
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>

            <div class="hr-line-dashed"></div>

            <div class="table-responsive">
              <table
                :class="auditTableClass"
                class="table table-striped table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th style="width: 2%">{{ userAuditColumn.id }}</th>
                    <th style="width: 3%">{{ userAuditColumn.name }}</th>
                    <th style="width: 3%">{{ userAuditColumn.action }}</th>
                    <th style="width: 5%">{{ userAuditColumn.uuid }}</th>
                    <th style="width: 5%">{{ userAuditColumn.requestDate }}</th>
                    <th style="width: 3%">{{ userAuditColumn.userId }}</th>
                    <th style="width: 2%">{{ userAuditColumn.data }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in userAuditData" :key="key">
                    <td>{{ value.id }}</td>
                    <td>{{ value.name }}</td>
                    <td>
                      <small
                        class="label"
                        :class="
                          value.action == 'Add'
                            ? 'label-primary'
                            : value.action == 'Update'
                            ? 'label-success'
                            : 'label-danger'
                        "
                        style="padding-left: 6%; padding-right: 6%"
                      >{{ value.action }}</small>
                    </td>
                    <td>{{ value.uuid }}</td>
                    <td>
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      &nbsp;&nbsp;
                      {{ value.requestDate }}
                    </td>
                    <td>{{ value.userId }}</td>
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#viewModal"
                        @click="addModalData(value.data)"
                      >
                        View &nbsp;&nbsp;
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ userAuditColumn.id }}</th>
                    <th>{{ userAuditColumn.name }}</th>
                    <th>{{ userAuditColumn.action }}</th>
                    <th>{{ userAuditColumn.uuid }}</th>
                    <th>{{ userAuditColumn.requestDate }}</th>
                    <th>{{ userAuditColumn.userId }}</th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <ViewModal :data="data"></ViewModal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTableService from "../../framework/plugin/DataTableService";
import AxiosService from "../../framework/plugin/AxiosService";
import Constant from "../../framework/plugin/Constant";
import ViewModal from "../components/common/ViewModal";
import SearchCriteria from "../../framework/plugin/SearchCriteria";
import Utils from "../../framework/plugin/Utils";

export default {
  data() {
    return {
      userAuditColumn: {
        id: "Id",
        name: "UI Name",
        action: "Action",
        uuid: "UUID",
        requestDate: "Time Stamp",
        userId: "User Id",
        data: "Data"
      },
      auditTableClass: "auditTableClass",
      auditTitle: "User Audit",
      userAuditData: {},
      data: {},
      userAuditModel: {
        name: "",
        action: "",
        uuid: "",
        requestDate: "",
        userId: ""
      },
      uiNames: [
        Constant.data.USER_ACTIVITY,
        Constant.data.GROUP_ACTIVITY,
        Constant.data.CUSTOMER_ACTIVITY,
        Constant.data.ROLE_ACTIVITY,
        Constant.data.API_ENDPOINT_ACTIVITY,
        Constant.data.API_DEFINITION_ACTIVITY,
        Constant.data.PROPERTY_ACTIVITY
      ],
      actions: [
        Constant.data.ADD_TITLE,
        Constant.data.DELETE_TITLE,
        Constant.data.UPDATE_TITLE
      ],
      searchCriteriaData: [],
      datePicker: "datefilter"
    };
  },
  components: {
    ViewModal
  },
  mounted: function() {
    var self = this;
    Ladda.bind(".ladda-button", { timeout: 2000 });

    $('input[name="' + this.datePicker + '"]').on(
      "apply.daterangepicker",
      function(ev, picker) {
        setTimeout(() => {
          self.assignDatePickerValue();
        }, 200);
      }
    );
    $('input[name="' + this.datePicker + '"]').on(
      "cancel.daterangepicker",
      function(ev, picker) {
        setTimeout(() => {
          self.assignDatePickerValue();
        }, 200);
      }
    );
    Utils.datePickerInitalizer(this.datePicker, 7);

    DataTableService.loadDataTableFooterSearch(
      this.auditTableClass,
      this.auditTitle
    );
  },
  created: function() {
    this.loadUserAuditData();
    this.generateSearchCriteriaData();
  },
  methods: {
     assignDatePickerValue: function() {
      console.log($("#" + this.datePicker).val());
      this.userAuditModel.requestDate = $("#" + this.datePicker).val();
    },
    loadUserAuditData: function() {
      const self = this;
      AxiosService.get(Constant.url.AUDIT_GET_ALL_USER_URI).then(result => {
        self.userAuditData = result;
      });
    },
    addModalData: function(value) {
      this.data = JSON.parse(value);
    },
    generateSearchCriteriaData: function() {
      const self = this;
      Utils.clearData(this.searchCriteriaData);
      $.each(this.userAuditModel, function(key, value) {
        if (!Utils.isEmptyStr(value)) {
          if ("requestDate" == key) {
            var data = Utils.splitStr(value, ":", 2);
            self.searchCriteriaData.push(
              new SearchCriteria(
                key,
                Utils.trimStr(data[0]) + " 00:00:00",
                Constant.criteria.GREATER_THAN_EQUAL
              )
            );
            self.searchCriteriaData.push(
              new SearchCriteria(
                key,
                Utils.trimStr(data[1]) + " 23:59:59",
                Constant.criteria.LESS_THAN_EQUAL
              )
            );
          } else {
            self.searchCriteriaData.push(
              new SearchCriteria(key, value, Constant.criteria.EQUAL)
            );
          }
        }
      });
    },
    submitData: function() {
      this.generateSearchCriteriaData();
      if (Utils.isEmptyStr(this.searchCriteriaData)) {
        toastr.error("Select at least one search criteria", "Error");
      } else {
        const self = this;
        AxiosService.post(
          Constant.url.AUDIT_SEARCH_USER_CRITERIA,
          this.searchCriteriaData
        ).then(result => {
          self.userAuditData = result;
          DataTableService.reLoadDataTable(self.auditTableClass);
          DataTableService.loadDataTable(self.auditTableClass, self.auditTitle);
        });
      }
    }
  }
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
